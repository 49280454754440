import { Card } from "@mui/material"
import React from "react"
import styled from "styled-components"

export const StyledCard = styled(props => <Card {...props} />)`
  margin: 0 10px;

  @media screen and (min-width: 600px) {
    margin: 0 10% 10% 10%;
  }
  @media screen and (min-width: 800px) {
    margin: 0 20% 10% 20%;
  }
`

export const CardTopContent = styled.div`
  padding: 20px;
`

export const StyledLink = styled.a`
  text-decoration: none;
  &:link {
    color: ${props => props.theme.palette.primary.dark};
  }
`
