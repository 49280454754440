import React from "react"

import { PrivacyPolicyPage } from "../components/sets/terms/PrivacyPolicyPage"
import { SEO } from "../components/atoms/SEO"

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <SEO />
      <PrivacyPolicyPage />
    </>
  )
}

export default PrivacyPolicy
